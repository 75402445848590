.accordion-button:focus {
    box-shadow: none;
    border-color: rgba(0, 0, 0, .125);
}

.login-w {
    min-width: 400px;
}

.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.rakete-font {
    font-family: "Kdam Thmor Pro", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.fs-7 {
    font-size: small;
}

.fs-8 {
    font-size: x-small;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    /* 100% der Bildschirmhöhe */
}

.login-button {
    max-width: 200px;
    padding: 20px;
    min-height: 39px;
    background: #b40202;
    padding-block: 7px;
    color: white;
    text-align: center;
}

.decline-button:hover {

    cursor: pointer;
    border-color: rgba(41, 41, 41, 0.616);
    color: rgba(41, 41, 41, 0.753);
}

.decline-button {
    max-width: 200px;
    padding: 20px;
    min-height: 39px;
    background: #ffffff;
    padding-block: 7px;
    color: rgba(128, 127, 127, 0.616);
    text-align: center;
    border-color: rgba(128, 127, 127, 0.616);
    border-style: solid;
}

.login-button:hover {

    cursor: pointer;
    background: #bb0505;
}

.secondary-button:hover {

    cursor: pointer;
    background: rgb(0, 134, 196);
}

.secondary-button {
    max-width: 200px;
    padding: 20px;
    min-height: 39px;
    background: rgb(0, 153, 224);
    padding-block: 7px;
    color: white;
    text-align: center;
    border: none;
}



.logo-container {
    display: inline-block;
    /* Zeigt die Container nebeneinander an */
}

.logo-text {
    margin-left: -20px;
}

.logo-nav-text {
    margin-left: -16px;
}

.input-btn {
    font-size: small;
}

.align-right {
    display: flex;
    justify-content: center;
    align-self: center;
}

.hover {
    transition-duration: 250ms;
}

.hover:hover {
    transform: scale(1.01);
    cursor: pointer;
}

.newsline {
    transition-duration: 250ms;
    max-width: 1000px;
    caret-color: transparent;
}

.events {
    transition-duration: 250ms;
    caret-color: transparent;
}

.events:hover {
    transform: scale(1.01);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.151);
    cursor: pointer;
}

.newsline:hover {
    transform: scale(1.01);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.151);
    cursor: pointer;
}

.icons {
    fill: rgb(110, 110, 110);
}

.icons:hover {
    transform: scale(1.2);
    cursor: pointer;
    fill: #bb0505;
}

.icon-edit {
    color: rgb(110, 110, 110);
}

.icon-edit:hover {
    transform: scale(1.2);
    cursor: pointer;
    color: #bb0505;
}

.m-w-5 {
    max-width: 750px;
}

.m-w-1 {
    width: 10px;
}

.no-text-cursor {
    caret-color: transparent;
}

.img-preview {
    max-width: 400px;
    max-height: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    width: 50%;

}

.modal .modal-footer {
    justify-content: center;
}

.avatar-p {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    transition-duration: 250ms;
}

.avatar-p:hover {
    transform: scale(1.2);
}

.fs-9 {
    font-size: 11px;
}

.role {
    width: 100px;
}


.profile-image {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    width: 150px;
    height: 150px;
    border-radius: 50%;
}


.admin {
    color: #bb0505;
    border-color: #bb0505b4;
}

.operator {
    color: #5fb80c;
    border-color: #5fb80cb4;
}

.user {
    color: #0597bb;
    border-color: #0597bbb4;
}

.div-justify-middle {
    display: flex;
    justify-content: center;
}

.icon-active {
    transform: scale(1.2);
    fill: #bb0505;
}

.icon-edit-active {
    transform: scale(1.2);
    color: #bb0505;
}

.items-right {
    display: flex;
    justify-content: end;
}

.add-button:hover {
    color: #bb0505;
    fill: #b40202;
}

.login-button[disabled] {
    background-color: #e9ecef;
    /* Hintergrundfarbe */
    color: #6c757d;
    /* Textfarbe */
    border-color: #ced4da;
    /* Randfarbe */
    cursor: not-allowed;
    /* Mauszeiger */
    opacity: 0.6;
    /* Opazität */
}

.img-leitung {
    max-width: 400px;
    max-height: 200px;
}

.text-cursor {
    caret-color: black;
}

.check {
    color: #62a027;
}

.check:hover {
    color: #62a027;
}

.decline {
    fill: #b40202;
}

.decline:hover {
    fill: #b40202;
}

.opp-logo {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    object-fit: contain;
    transition-duration: 250ms;
}


.accordion {
    --bs-border-width: 0px;
    --bs-primary-bg-subtle: #ffffff;
    box-shadow: none;
}

.form-control {
    --bs-border-width: 1px;
}

.custom-dropdown-menu {
    top: 50px !important;
    left: -100px !important;
    /* Adjust this value to move the dropdown further left */

}

.nav-right {
    position: absolute;
    top: 17px;
    right: 10px;
    display: flex;
    align-items: center;
}

.navbar .avatar-nav {
    cursor: pointer;
}

.navbar-brand {
    margin-right: auto;
}


.avatar-dropdown {
    margin-right: 20px;
    /* Default margin when both toggles are visible */
    transition: margin-right 0.3s ease;
    /* Smooth transition */
}

.navbar-toggler {
    margin-left: 10px;
}

@media (max-width: 767.98px) {

    /* Adjust based on your navbar's break point */
    .avatar-dropdown {
        margin-right: 0px;
        /* Move avatar toggle 20px to the left when the navbar toggle is hidden */
    }
}

.avatar-middle {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    transition-duration: 250ms;
}

.forgot-password {
    transition-duration: 250ms;
}

.forgot-password:hover {
    color:#bb0505;
    cursor: pointer;
    transform: scale(1.05);
    transition-duration: 250ms;
}

body {
    display: flex;
    background: linear-gradient(135deg, #a70000, #720000);
    background-size: cover;
    background-attachment: fixed;
}

.headline {
    color:#ffb7b7;
}

.h-green {
    color:#acfd42
}



html, body {
    overflow-y: overlay; 
}

::-webkit-scrollbar {
    width: 10px; 
}

::-webkit-scrollbar-track {
    background-color: rgb(153, 0, 0); 
}

::-webkit-scrollbar-thumb {
    background-color: rgba(202, 0, 0, 0.8);
    border-radius: 10px; 
    border: 3px solid transparent;
    background-clip: padding-box; 
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255, 0, 0, 1); 
}

body {
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 0, 0, 0.8) transparent;
}

.swiper-button-prev, .swiper-button-next {
    color: #bb0505; /* Beispiel: Rakete-Rot */
    transform: scale(0.7); /* Etwas kleiner machen */
}
.swiper-pagination-bullet-active {
    background-color: #bb0505 !important; /* Beispiel: Rakete-Rot für aktive Punkte */
}
.tournament-slide-card {
    height: auto; /* Höhe automatisch anpassen lassen */
    /* Stellt sicher, dass die Karte innerhalb des Slides zentriert ist, falls nötig */
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box; /* Wichtig für Padding/Border */
    padding: 0 5px; /* Kleiner horizontaler Abstand zwischen Slides durch Padding */
}
.tournament-card-content {
     width: 100%; /* Volle Breite innerhalb des Slides */
     max-width: 350px; /* Maximale Breite einer Karte */
     border: none; /* border-0 */
     border-radius: 0.75rem; /* rounded-4 */
     padding: 0.5rem; /* py-2 */

     background-color: white; /* Standard Kartenhintergrund */
     display: flex; /* Stellt sicher, dass Link die Höhe bestimmt */
     flex-direction: column;
}